import React from 'react'
import _ from 'lodash'
import Layout from '../components/Layout'
import { TitleSection } from '../components/common/Index.styled'
import Section from '../components/common/Section'
import { FormattedMessage } from 'react-intl'
import SectionHeaderImage from '../components/common/SectionHeaderImage'
import image_background from '../images/solutions/laboral.png'
import { ContractButtonSmall } from '../components/Solutions/SolutionDetail.styled'
import FooterContact from './../components/FooterContact'

const Laboral = props => (
  <Layout
    title="Servicio Laboral"
    description="En general un servicio completo con nuestra solución laboral para que tenga una operación óptima de su empresa y consiga disminuir el riesgo laboral de la mano de nuestros abogados y contadores especialistas."
    {...props}
  >
    <SectionHeaderImage image={image_background} position="90% 0" />
    <Section>
      <TitleSection>
        <FormattedMessage id="solutions.laboral.title" />
      </TitleSection>
      <div>
        <p>
          <FormattedMessage id="solutions.laboral.content.p1" />
        </p>
        <p>
          <FormattedMessage id="solutions.laboral.content.p2" />
        </p>
        <h2>
          <FormattedMessage id="solutions.laboral.content.subtitle" />
        </h2>
        <ul>
          {_.map(_.times(17, String), (value, key) => (
            <li key={key}>
              <FormattedMessage
                id={`solutions.laboral.content.list.item${value}`}
              />
            </li>
          ))}
        </ul>
        <p>
          <FormattedMessage id="solutions.laboral.content.p2" />
        </p>
      </div>
      <ContractButtonSmall to="/contacto/" small>
        <FormattedMessage id="element.button_hire" />
      </ContractButtonSmall>
    </Section>

    <FooterContact />
  </Layout>
)

export default Laboral
